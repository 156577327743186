var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "span",
                    { staticClass: "secondary--text font-weight-bold" },
                    [_vm._v("Assignments")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              !_vm.assignmentsExist && _vm.isSpaceAdmin
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "secondary" },
                      on: { click: _vm.startAssignmentTour },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Create a new assignment"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.filteredBundlesByType.length && !_vm.fetchingSpaceBundles
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("v-spacer"),
                      _c("v-text-field", {
                        staticClass: "mr-2",
                        attrs: {
                          "prepend-inner-icon": "mdi-filter",
                          autofocus: "",
                          "hide-details": "",
                          solo: "",
                          flat: "",
                          "background-color": "grey lighten-4",
                          dense: "",
                          label: "Filter assignments...",
                          clearable: "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.fetchingSpaceBundles,
                            icon: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$store.dispatch(
                                "spaceStore/fetchSpaceBundles",
                                _vm.$route.params.sid
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("refresh")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      search: _vm.search,
                      "item-key": "bid",
                      options: _vm.tableOptions,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions,
                      },
                      headers: _vm.headers,
                      items: _vm.filteredBundlesByType,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({
                            pagination,
                            options,
                            updateOptions,
                          }) {
                            return [
                              _c("v-data-footer", {
                                staticClass: "no-border",
                                attrs: {
                                  pagination: pagination,
                                  options: options,
                                  itemsPerPageOptions: _vm.itemsPerPageOptions,
                                  "items-per-page-text":
                                    "$vuetify.dataTable.itemsPerPageText",
                                },
                                on: { "update:options": updateOptions },
                              }),
                            ]
                          },
                        },
                        {
                          key: `item.long_id`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "py-2" },
                                [
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  class: [
                                                    hover
                                                      ? "secondary--text"
                                                      : "secondary--text",
                                                    "subtitle-1",
                                                    "font-weight-bold",
                                                    "text-decoration-none",
                                                  ],
                                                  style: hover
                                                    ? "cursor: pointer;"
                                                    : "",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.gotoAssignment(
                                                        item.bid
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.long_id) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  item.description
                                    ? _c("div", { staticClass: "subtitle-2" }, [
                                        _vm._v(_vm._s(item.description)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.assignment_status`,
                          fn: function ({ item }) {
                            return [
                              _c("v-chip", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(item.assignment_status)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.submission_status`,
                          fn: function ({ item }) {
                            return [
                              item.submission_status === "No submissions"
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "orange" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.submission_status) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v(_vm._s(item.submission_status))]
                                  ),
                            ]
                          },
                        },
                        {
                          key: `item.allow_handins_until`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "subtitle-2",
                                      class: _vm.handinDeadlineStyle(
                                        item.allow_handins_until
                                      ).color,
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateTimeToHuman")(
                                              item.allow_handins_until
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-beginning" },
                                [
                                  !_vm.isSpaceAdmin &&
                                  !_vm.isAssignmentOverdue(
                                    item.allow_handins_until
                                  ) &&
                                  !_vm.isMasterInstance
                                    ? _c("TheSnapshotAssignmentSubmitDialog", {
                                        attrs: { bundleData: item },
                                      })
                                    : _vm._e(),
                                  _vm.isSpaceAdmin
                                    ? _c("BundleDeleteDialog", {
                                        attrs: { bundleData: item },
                                      })
                                    : _vm._e(),
                                  _vm.isSpaceAdmin
                                    ? _c("BundleEditDialog", {
                                        attrs: { bundleData: item },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              )
            : !_vm.filteredBundlesByType.length && !_vm.fetchingSpaceBundles
            ? _c(
                "div",
                [
                  _c(
                    "v-alert",
                    { attrs: { text: "", prominent: "", type: "info" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between w-100",
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("No assignments found"),
                            ]),
                            _c("span", [
                              _vm._v(
                                " So far no assignments have been created. "
                              ),
                              _vm.isSpaceAdmin
                                ? _c("span", [
                                    _vm._v(
                                      " You can create new assignments by staging and sharing objects (files, tables, and applications) as an assignment. "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm.isSpaceAdmin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href: "https://docs.nuvolos.cloud/user-guides/education-guides/setting-assignments",
                                    target: "_blank",
                                    outlined: "",
                                    small: "",
                                    color: "info",
                                  },
                                },
                                [_vm._v(" Learn more ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.fetchingSpaceBundles
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 },
                  ],
                  function (item) {
                    return _c("div", { key: item.id, staticClass: "my-6" }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-avatar-two-line" },
                          }),
                        ],
                        1
                      ),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }